import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromCorePresentations from './presentation-components';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './container-components/loading/loading.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    fromCorePresentations.COMPONENTS,
    LoadingComponent
  ],
  exports: [fromCorePresentations.COMPONENTS]
})
export class CoreModule {}
