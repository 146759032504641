import { UserDetails } from '@developer-portal/shared/models';
import { Session } from '@developer-portal/shared/models';
import { HttpError } from '@developer-portal/shared/models';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../+state/user.actions';
export interface State {
  user: Partial<UserDetails>;
  session: Session;
  loading: boolean;
  loaded: boolean;
  isInternalUserLoginEnabled: boolean;
  error: HttpError;
}

export const initialState: State = {
  user: null,
  session: {
    active: false,
  },
  loading: false,
  loaded: false,
  isInternalUserLoginEnabled: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.LoadUser, (state) => ({
    ...state,
    
  })),
  on(UserActions.LoadSuccess, (state, {user}) => ({
    ...state,
    loading: false,
    loaded: true,
    user: user,
    isInternalUserLoginEnabled: checkEnvHost(),
    session: {
      active: true,
    },
    error: null,
  })),
  on(UserActions.LoadFail, (state, {error}) => ({
    ...state,
    loaded: false,
    error: error,
  })),
  on(UserActions.ClearUserSession, (state) => ({
    ...state,
    user: null,
    isInternalUserLoginEnabled: checkEnvHost(),
    session: {
      active: false,
    },
    loading: false,
    loaded: false,
    error: null,
  }))
);

function checkEnvHost() {
  return !(
    window.location.host === 'developer.fanniemae.com' ||
    window.location.host === 'developer-clve-a.fanniemae.com' ||
    window.location.host === 'developer-clve.fanniemae.com'
  );
}

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const getUser = (state: State) => state.user;

export const getSession = (state: State) => state.session;

export const getLoading = (state: State) => state.loading;

export const getLoaded = (state: State) => state.loaded;

export const getUserGroups = (state: State) => state.user.groups;

export const getIsInternalUserLoginEnabled = (state: State) =>
  state.isInternalUserLoginEnabled;
