import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'developer-portal-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
})
export class MaintenanceBannerComponent implements OnInit {
  @Input() maintenanceBannerInfo;
  @Output() onCloseIconClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onCloseClick() {
    this.onCloseIconClick.emit();
  }
}
