import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromFeedbackService from './post-feedback.reducer';
import * as fromRoot from '../../../store/reducers';
import * as fromFeedbackModalService from './open-feedback-modal.reducer';

export interface PostFeedbackState {
  feedbackPage: fromFeedbackService.State;
}

export interface ShowFeedbackModalState {
  feedbackModalPage: fromFeedbackModalService.State;
}

export interface State extends fromRoot.State {}

export const feedbackReducers: ActionReducerMap<PostFeedbackState> = {
  feedbackPage: fromFeedbackService.reducer,
};

export const showFeedbackModalReducers: ActionReducerMap<ShowFeedbackModalState> =
  {
    feedbackModalPage: fromFeedbackModalService.reducer,
  };

export const selectFeedbackPageState =
  createFeatureSelector<PostFeedbackState>('PostFeedback');
export const showFeedbackModalPageState =
  createFeatureSelector<ShowFeedbackModalState>('OpenFeedbackModal');

export const getPostFeedbackPageState = createSelector(
  selectFeedbackPageState,
  (state: PostFeedbackState) => state.feedbackPage
);

export const postUserFeedback = createSelector(
  getPostFeedbackPageState,
  fromFeedbackService.getFeedbackLoaded
);

export const getFeedbackModalPageState = createSelector(
  showFeedbackModalPageState,
  (state: ShowFeedbackModalState) => state.feedbackModalPage
);

export const getModalDisplayStatus = createSelector(
  getFeedbackModalPageState,
  fromFeedbackModalService.openFeedbackModalSuccess
);

// export const openFeedbackModal = createSelector(
//   getFeedbackModalPageState,
//   getModalDisplayStatus,
//   (s1: fromFeedbackModalService.State, showFeedbackModal: boolean) => {
//       if (!isNullOrUndefined(showFeedbackModal)) {
//           return showFeedbackModal;
//       }
//   }
// );
