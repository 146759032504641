import {
  PostFeedbackActionTypes,
  PostFeedbackActionsUnion,
} from '../actions/post-feedback.action';
import { HttpError } from '../../../models/http-error.model';

export interface State {
  postResult: any;
  loaded: boolean;
  error: HttpError;
  postbody?: {};
}

export const initialState: State = {
  loaded: false,
  postbody: {},
  error: null,
  postResult: {},
};

export function reducer(
  state = initialState,
  action: PostFeedbackActionsUnion
): State {
  switch (action.type) {
    case PostFeedbackActionTypes.PostFeedbackLoad: {
      return {
        ...state,
        loaded: true,
        postbody: action.payload,
      };
    }

    case PostFeedbackActionTypes.PostFeedbackSuccess: {
      return {
        ...state,
        loaded: true,
        error: null,
        postResult: action.payload,
      };
    }

    case PostFeedbackActionTypes.PostFeedbackFail: {
      const error: HttpError = action.payload;
      return {
        ...state,
        loaded: true,
        error: error,
      };
    }

    case PostFeedbackActionTypes.ClearPostFeedback: {
      return initialState;
    }

    default:
      return state;
  }
}

export const getFeedbackLoaded = (state: State) => state.loaded;
export const getFeedbackPostBody = (state: State) => state.postbody;
export const postFeedbackSuccess = (state: State) => state.postResult;
