import { createAction, props } from '@ngrx/store';
import { HttpError } from '../../models/http-error.model';

export const LoadMaintenanceBannerContent = createAction(
  '[Maintenance Banner Content] Maintenance Banner Content'
);

export const LoadMaintenanceBannerContentSuccess = createAction(
  '[Maintenance Banner Content] Maintenance Banner Content Success',
  props<{ data: any }>()
);

export const LoadMaintenanceBannerContentFail = createAction(
  '[Maintenance Banner Content] Maintenance Banner Content Fail',
  props<{ err: HttpError }>()
);
export const ClearMaintenanceBannerContent = createAction(
  '[Maintenance Banner Content] Clear Maintenance Banner Content'
);
