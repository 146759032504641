import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromFeatureToggleReducer from './+state/feature-toggle.selectors';
import { FeatureToggleEffects } from './+state/feature-toggle.effects';
import { FeatureToggleActionsService } from './services/feature-toggle-actions.service';
import { FeatureToggleService } from './services/feature-toggle.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFeatureToggleReducer.FEATURE_TOGGLE_FEATURE_KEY,
      fromFeatureToggleReducer.featureToggleReducer
    ),
    EffectsModule.forFeature([FeatureToggleEffects]),
  ],
  providers: [FeatureToggleActionsService, FeatureToggleService],
})
export class SharedDataAccessFeatureToggleModule {}
