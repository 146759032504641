import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUser from '../+state/index';
import * as UserActions from '../+state/user.actions';

@Injectable({
  providedIn: 'root',
})
export class SessionOrchestratorService {
  constructor(private store: Store<fromUser.UserState>) {}

  getUserDetails(isPublicLogin: boolean, clearCDXSession: boolean): void {
    this.store.dispatch(UserActions.LoadUser({isPublicLogin: isPublicLogin, clearCDXSession: clearCDXSession}));
  }

  clearSession(): void {
    (window as any).localStorage.clear();
  }
}
