import { Action } from '@ngrx/store';

export enum AppActionTypes {
  LoadApp = '[App] Load Apps',
  Spinner = '[App] Load Spinner',
}

export class LoadApp implements Action {
  readonly type = AppActionTypes.LoadApp;
}

export class LoadSpinner implements Action {
  readonly type = AppActionTypes.Spinner;
  constructor(public payload: boolean) {}
}

export type AppActionUnion = LoadApp | LoadSpinner;
