import { createReducer, on } from '@ngrx/store';
import * as FeatureToggleActions from './feature-toggle.actions';
import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';

export interface State {
  loading: boolean; // is the FeatureTogle list being loading
  loaded: boolean; // has the FeatureToggle list been loaded
  noAuthFeatures: any;
  authFeatures: any;
  noAuthError: HttpErrorResponse | null; // last known error (if any)
  authError: HttpErrorResponse | null;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  noAuthFeatures: null,
  authFeatures: null,
  noAuthError: null,
  authError: null,
};

export const reducer = createReducer(
  initialState,
  on(FeatureToggleActions.loadNoAuthFeatureToggle, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    noAuthFeatures: null,
    noAuthError: null,
  })),

  on(
    FeatureToggleActions.loadNoAuthFeatureToggleSuccess,
    (state, { featureToggle }) => ({
      ...state,
      loading: false,
      loaded: true,
      noAuthFeatures: featureToggle,
      noAuthError: null,
    })
  ),

  on(FeatureToggleActions.loadNoAuthFeatureToggleFail, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    noAuthFeatures: null,
    error: error,
  })),

  on(FeatureToggleActions.LoadAuthFeatureToggle, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    authFeatures: null,
    authError: null,
  })),

  on(
    FeatureToggleActions.LoadAuthFeatureToggleSuccess,
    (state, { featureToggle }) => ({
      ...state,
      loading: false,
      loaded: true,
      authFeatures: featureToggle,
      authError: null,
    })
  ),

  on(FeatureToggleActions.LoadAuthFeatureToggleFail, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    authFeatures: null,
    authError: error,
  })),

  on(FeatureToggleActions.cleanFeatureToggle, (state) => ({
    ...state,
    loaded: false,
    loading: false,
    noAuthFeatures: null,
    authFeatures: null,
    noAuthError: null,
    authError: null,
  }))
);

export const getFeatureTogglesState = (state: State) => state;
export const getFeatureTogglesLoading = (state: State) => state.loading;
export const getFeatureTogglesLoaded = (state: State) => state.loaded;
export const getNoAuthFeatureToggles = (state: State) => state.noAuthFeatures;
export const getAuthFeatureToggles = (state: State) => state.authFeatures;
