import { Injectable } from '@angular/core';
import { Util } from '../../core/util';

declare let ga: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public Util: Util;

  constructor() {
    this.Util = new Util();
  }

  loadGoogleAnalyticsScript(): void {
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = +new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m?.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      'https://www.google-analytics.com/analytics.js',
      'ga'
    );
    (window as any).ga('create', 'UA-64555238-10', 'auto');
  }

  logNavigationEvent(event): void {
    if (
      event?.urlAfterRedirects != null &&
      event?.urlAfterRedirects != undefined
    ) {
      (window as any).ga('set', 'page', event.urlAfterRedirects);
      (window as any).ga('send', 'pageview');
    }
  }
}
