import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'developer-portal-catalog-hero',
  templateUrl: './catalog-hero.component.html',
  styleUrls: ['./catalog-hero.component.scss'],
})
export class CatalogHeroComponent implements OnInit {
  @Input() title: string;
  @Input() description?: string;
  @Input() backgroundUrl?: string;
  @Input() headerIconUrl?: string;

  constructor() {}

  ngOnInit(): void {}
}
