import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError as _throw, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BannerInfo } from '../../models/maintenance-banner.model';
import { environment } from '../../../environments/environment';
import { PrePostPaths } from '../../../../../../libs/shared/data-access/src/constants/pre-post-paths.constants';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceBannerCmsService {
  constructor(private http: HttpClient) {}

  getbannerInfo(): Observable<BannerInfo[]> {
    const url =
      PrePostPaths.pre.path +
      environment.api_end_points.v1.cms_maintainance_banner;
    const header = new HttpHeaders({
      'content-type': 'application/json',
      'x-fnma-channel': 'api',
    });

    return this.http.get<any>(url, { headers: header }).pipe(
      map((response: any) => response),
      catchError((error) => _throw(error))
    );
  }
}
