import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import * as MaintenanceBannerActions from '../actions/maintenance-banner.action';
import { MaintenanceBannerCmsService } from '../services/maintenance-banner.service';
import { Store } from '@ngrx/store';
import * as fromCmsContentreducers from '../../store/reducers';

@Injectable()
export class MaintenanceBannerEffects {
  constructor(
    private actions$: Actions,
    private httpBannerCmsService: MaintenanceBannerCmsService,
    private store: Store<fromCmsContentreducers.State>
  ) {}

  getCmsContent = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceBannerActions.LoadMaintenanceBannerContent),
      switchMap((action) =>
        this.httpBannerCmsService.getbannerInfo().pipe(
          map((response) => ({
            type: '[Maintenance Banner Content] Maintenance Banner Content Success',
            data: response,
          })),
          catchError((error) =>
            of({
              type: '[Maintenance Banner Content] Maintenance Banner Content Fail',
              err: error,
            })
          )
        )
      )
    )
  );
}
