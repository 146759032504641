import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dp-application-error',
  templateUrl: './application-error.component.html',
  styleUrls: ['./application-error.component.scss'],
})
export class ApplicationErrorComponent {
  public serviceErrorText = 'Something went wrong.';
  public checkLater =
    'The page you are trying to reach is temporarily unavailable, please try again later.';

  constructor() {}
}
