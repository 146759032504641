import { ErrorActionsUnion, ErrorActionTypes } from '../actions/error.actions';

export interface State {
  error: any;
}

export const initialState: State = {
  error: null,
};

export function reducer(
  state = initialState,
  action: ErrorActionsUnion
): State {
  switch (action.type) {
    case ErrorActionTypes.SetErrors: {
      return {
        error: action.payload,
      };
    }

    case ErrorActionTypes.ClearErrors: {
      return {
        error: initialState,
      };
    }

    default:
      return state;
  }
}

export const getError = (state: State) => state.error;
