export const PrePostPaths = {
  pre: {
    path: '/fv7ui-public/web/fv7/api/noauth',
  },
  post: {
    path: '/fv7ui-secured/web/fv7/api/auth',
  },
  postPublic: {
    path: '/fv7ui-pubauth/web/fv7/api/auth'
  }
} as const;
