import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserDetails } from '@developer-portal/shared/models';
import { PrePostPaths } from '../../../../../constants/src/lib/pre-post-paths.constants';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(public http: HttpClient) {}

  userDetails(isPublicLogin: boolean, clearCDXSession: boolean): Observable<any> {
   let url: string;
    if(isPublicLogin) {
      if(clearCDXSession){
        url = PrePostPaths.postPublic.path + '/v1/userdetails?clear_cdx_session=true';
      } else {
        url = PrePostPaths.postPublic.path + '/v1/userdetails';
      }
    }
    else {
      url = PrePostPaths.post.path + '/v1/userdetails';
    }
    
    return this.http
      .get<Partial<UserDetails>>(url)
      .pipe(catchError(this.userDetailsErrorHandler));
  }

  private userDetailsErrorHandler(error: HttpResponseBase) {
    return throwError('User is unauthorized');
  }
}