<section
  class="
    text-left
    w-full
    flex
    justify-center
    md:h-48
    relative
    hero-banner-container
  "
>
  <img
    class="w-full h-full absolute banner_bg md:h-48"
    [src]="backgroundUrl"
    [ngStyle]="{'opacity': opacity ? opacity : '0.15'}"
    [alt]="imgAltName ? imgAltName : 'banner-background'"
  />
  <div class="hero-banner-info p-4 relative">
    <img
      [src]="headerIconUrl"
      class="h-24 w-24"
      *ngIf="headerIconUrl"
      alt="banner-icon"
    />
    <div class="pl-4 pt-5">
      <h2 class="font-sans font-semibold inline-block text-header-xs md:text-4xl max-w-none md:max-w-3xl"
          [ngClass]="{'text-header-blue': fontColor !== 'white', 'text-white': fontColor === 'white'}">
        {{ title }}
      </h2>
      <p class="font-sans font-normal leading-5 text-base m-0 text-left pt-1 pr-4"
         [ngClass]="{'text-black': fontColor !== 'white', 'text-white': fontColor === 'white'}">
        {{ description }}
      </p>
    </div>
    <div
      class="back-anchor-container absolute top-4 left-4 md:top-8 md:left-8"
      *ngIf="isBackNav"
    >
      <img
        class="inline-block"
        src="./assets/use-cases/Back_icon.svg"
        alt="back-icon"
      />
      <a class="back-anchor text-bright-blue pl-1" href="#/{{ navPath }}">{{
        navText
      }}</a>
    </div>
  </div>
</section>
