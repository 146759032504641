<div class="app-wrapper">
  <div class="app-content">
    <ng-container *ngIf="loadSpinner$ | async">
      <div id="overlay">
        <div class="loader"></div>
        <div class="loader-text">Generating key, please wait…</div>
      </div>
    </ng-container>
    <ng-container *ngIf="hasGlobalError">
      <dp-application-global-error
        class="globalError"
        [routeTechSupport]="routeTechSupport"
        [statusText]="errorStatusText"
        [status]="errorStatus"
        (closeErrorAlert)="closeGlobalErrorComponent($event)"
      >
      </dp-application-global-error>
    </ng-container>

    <ng-container *ngIf="maintenanceBannerInfo && showBanner">
      <developer-portal-maintenance-banner
        [maintenanceBannerInfo]="maintenanceBannerInfo"
        class="maintenance-banner relative"
        (onCloseIconClick)="closeBanner()"
      >
      </developer-portal-maintenance-banner>
    </ng-container>

    <div class="browser-message" *ngIf="showBrowserMessage">
      <dp-application-global-error
        class="globalError"
        [routeTechSupport]="false"
        [statusText]="
          'As an industry standard, this browser will no longer be supported.'
        "
        [status]="'browserMessage'"
        (closeErrorAlert)="closeGlobalErrorComponent($event)"
      >
      </dp-application-global-error>
    </div>

    <!-- Do not show Header, footer and feedback on Error Pages. -->
    <ng-container *ngIf="router.url !== '/resource-not-found'">
      <dp-header
        [siteName]="siteName"
        [siteMenu]="userMenu"
        [loginHover]="loginHover"
        dpHeaderSpyScroll
        (onMenuItemLinkEventEmitter)="onUserMenuClickEventEmitter($event)"
        [ngClass]="{ appErrorHeader: hasGlobalError }"
      >
      </dp-header>
    </ng-container>

    <section
      class="main"
      [ngClass]="{
        appErrorHeader: hasGlobalError,
        appErrorMarginBottom: hasGlobalError
      }"
      dpHeaderSpyScroll
    >
      <router-outlet></router-outlet>
    </section>

    <!-- Do not show Header, footer and feedback on Error Pages. -->
    <ng-container *ngIf="router.url !== '/resource-not-found'">
      <dp-footer
        [ngClass]="{ appError: appError }"
        [siteFooter]="landingFooter"
      >
      </dp-footer>
    </ng-container>

    <router-outlet name="application"></router-outlet>
    <router-outlet name="landing-page"></router-outlet>
  </div>
</div>

<!-- Do not show Header, footer and feedback on Error Pages. -->
<div *ngIf="router.url !== '/resource-not-found'">
  <app-feedback></app-feedback>
</div>
