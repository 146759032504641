import { createAction, props } from '@ngrx/store';
import { Banner } from '../../models/banner.model';
import { HttpError } from '../../models/http-error.model';

export const LoadAllBannerInfo = createAction('[App] Load All Banner Info');

export const LoadAllBannerInfoSuccess = createAction(
  '[App] Load All Banner Info Success',
  props<{ data: Banner[] }>()
);

export const LoadAllBannerInfoFail = createAction(
  '[App] Load All Banner Info Fail',
  props<{ err: HttpError }>()
);
