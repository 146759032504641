import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Util } from '../core/util';
import * as fromUser from '@developer-portal/shared/data-access/user';
import {
  ClearPostFeedback,
  PostFeedbackLoad,
} from './store/actions/post-feedback.action';
import * as fromFeedback from './store/reducers';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements AfterViewInit {
  public feedbackForm: UntypedFormGroup;
  public showFB = false;
  public showFBHeader = false;
  public showFBForm = false;
  public showTY = false;
  public isFeedbackPosted$: Observable<boolean>;
  public siteKey: string = Util.GOOGLE_SITE_KEY;
  public captchaToken$: Observable<any>;
  public isCaptchaTrue: boolean;
  public openFeedbackModal$: Observable<boolean>;
  public destroyed$ = new Subject();

  public state = false;
  public text: string;
  private inside = false;
  public ispopUpShow: boolean = false;
  public size = 'normal';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<fromFeedback.State>,
    private eRef: ElementRef
  ) {
    this.feedbackForm = new UntypedFormGroup({
      feeling: new UntypedFormControl(),
      category: new UntypedFormControl(),
      userComment: new UntypedFormControl(),
    });
    this.captchaToken$ = store.select(fromUser.getCaptchaValue);
    this.isFeedbackPosted$ = store.select(fromFeedback.postUserFeedback);
    this.openFeedbackModal$ = store.select(fromFeedback.getModalDisplayStatus);
  }

  ngAfterViewInit() {
    this.addGoogleReCaptchaScript();
    this.isCaptchaTrue = false;
    this.openFeedbackModal$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((showFeedbackModal) => {
        if (showFeedbackModal) {
          this.showDialog();
        }
      });
  }

  addGoogleReCaptchaScript(): void {
    const script = document.createElement('script');
    let googleSiteKey = '';
    Object.keys(environment.pingEnvironmentUrls).forEach((k, i) => {
      if (k === window.location.host) {
        googleSiteKey = environment.pingEnvironmentUrls[k]['googleSiteKey'];
      }
    });

    script.src =
      'https://www.google.com/recaptcha/api.js?render=' + googleSiteKey;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === 'Escape' || event.key === 'Esc' || event.key === '27') {
      this.hideDialog();
    }
  }

  @HostListener('document:click', ['$event'])
  fbClicked(event) {
    this.eRef.nativeElement.contains(event.target);
  }

  toggle() {
    this.state = !this.state;
    this.hideDialog();
  }

  showDialog() {
    this.showFB = true;
    this.showFBHeader = true;
    this.showFBForm = true;
    this.showTY = false;
  }

  hideDialog() {
    this.feedbackForm.reset();
    this.showFB = false;
    this.isCaptchaTrue = false;
  }

  submitFeedback() {
    if (this.feedbackForm.valid) {
      this.checkCaptchaAndPostFeedback();
      this.isCaptchaTrue = false;
    }
  }

  checkCaptchaAndPostFeedback() {
    let environmentName = '';
    let googleSiteKey = '';
    Object.keys(environment.pingEnvironmentUrls).forEach((k, i) => {
      if (k === window.location.host) {
        environmentName = environment.pingEnvironmentUrls[k]['hostEnvironment'];
        googleSiteKey = environment.pingEnvironmentUrls[k]['googleSiteKey'];
      }
    });
    const that = this;
    (<any>window).grecaptcha
      .execute(googleSiteKey, { action: 'dpFeedback' })
      .then(function (token) {
        const postBody = {
          ...formValue,
          googleV3Token: token,
          environment: environmentName,
        };
        that.store.dispatch(new PostFeedbackLoad(postBody));
        that.feedbackForm.reset();

        that.isFeedbackPosted$.subscribe((loaded: boolean) => {
          if (loaded) {
            that.showFBForm = false;
            that.showFBHeader = false;
            that.showTY = true;
            that.store.dispatch(new ClearPostFeedback());
          }
        });
      });

    const formValue = this.feedbackForm.value;
  }

  clickedOutSide(event) {
    if (
      event.target.className === 'fb_modal' ||
      event.target.id === 'fbModal'
    ) {
      this.ispopUpShow = false;
      this.hideDialog();
    }
  }
}
