import {
  createFeatureSelector,
  createSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromFeatureToggleReducer from './feature-toggle.reducer';

export const FEATURE_TOGGLE_FEATURE_KEY = 'featureToggle';

// Lookup the 'FeatureToggle' feature state managed by NgRx
export interface FeatureToggleState {
  featureToggleState: fromFeatureToggleReducer.State;
}

export const featureToggleReducer: ActionReducerMap<FeatureToggleState> = {
  featureToggleState: fromFeatureToggleReducer.reducer,
};

export const featureToggleState = createFeatureSelector<FeatureToggleState>(
  FEATURE_TOGGLE_FEATURE_KEY
);

export const selectFeatureToggleState = createSelector(
  featureToggleState,
  (state: FeatureToggleState) => state.featureToggleState
);

export const selectNoAuthFeatureToggleEntities = createSelector(
  selectFeatureToggleState,
  fromFeatureToggleReducer.getNoAuthFeatureToggles
);

export const selectAuthFeatureToggleEntities = createSelector(
  selectFeatureToggleState,
  fromFeatureToggleReducer.getAuthFeatureToggles
);

export const selectIsLoaded = createSelector(
  selectFeatureToggleState,
  fromFeatureToggleReducer.getFeatureTogglesLoaded
);

export const selectIsLoading = createSelector(
  selectFeatureToggleState,
  fromFeatureToggleReducer.getFeatureTogglesLoading
);
