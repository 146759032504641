import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dpLogoScrollMobile]',
})
export class LogoScrollMobileDirective {
  constructor(private el: ElementRef) {}

  @HostListener('window:scroll') onWindowScroll() {
    if (window.pageYOffset > 160) {
      this.changeWidth(0);
      this.changeOpacity(0);
    } else {
      this.changeWidth(125 - window.pageYOffset + 'px');
      this.changeOpacity(40 / window.pageYOffset);
    }
  }

  private changeWidth(width: any) {
    this.el.nativeElement.style.width = width;
  }

  private changeOpacity(opacity: number) {
    this.el.nativeElement.style.opacity = opacity;
  }
}
