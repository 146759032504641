<!-- Trigger Modal -->
<div class="feedback" (click)="showDialog()">
  <svg>
    <path
      class=""
      d="M12.6146667,2.02066667 L3.41466667,2.02066667 C2.63333333,2.02066667 2,2.654 2,3.43533333 L2,13.9893333 L4.656,11.3333333
        L12.614,11.3333333 C13.3953333,11.3333333 14.0286667,10.7 14.0286667,9.91866667 L14.0286667,3.43533333 C14.0286667,2.654
        13.3953333,2.02066667 12.614,2.02066667 L12.6146667,2.02066667 Z M9.43733333,9.646 L3.99533333,9.646 L3.99533333,8.646 L9.43733333,8.646
        L9.43733333,9.646 Z M12.0293333,7.25 L3.996,7.25 L3.996,6.33333333 L12.0293333,6.33333333 L12.0293333,7.25 Z M12.0293333,5
        L3.996,5 L3.996,4.042 L12.0293333,4.042 L12.0293333,5 Z"
      id="path-1"
    ></path>
  </svg>
  <p>Feedback</p>
</div>

<!-- Modal Window -->
<div
  class="fb_modal"
  id="fbModal"
  *ngIf="showFB"
  (click)="clickedOutSide($event)"
>
  <div>
    <a class="close-button" (click)="hideDialog()"></a>
    <p class="header_p" id="fb_Header" *ngIf="showFBHeader">Your Feedback</p>
    <div class="ty_div" id="tyDiv" *ngIf="showTY">
      <img src="assets/icons/Feedback Icon.svg" alt="" class="inline-block" />
      <p class="header_p" id="fb_Header">Thank you for your feedback.</p>
      <button class="closeBtn" (click)="hideDialog()">Close</button>
    </div>
    <form
      class="feedback_form"
      id="fb_form"
      *ngIf="showFBForm"
      [formGroup]="feedbackForm"
      (ngSubmit)="submitFeedback()"
    >
      <p class="first_p">
        We would like to know your feedback to improve the portal.
      </p>
      <div class="feelings_div">
        <p>Your overall feelings about Developer Portal experience!</p>
        <div class="user_feeling">
          <div class="selection">
            <input
              id="bad"
              value="bad"
              type="radio"
              name="feeling"
              formControlName="feeling"
              required
            />
            <label id="bad-l" for="bad">
              <svg class="inline-block">
                <path
                  d="M24,28.032 C28.688,28.032 32.626,30.844 34.218,34.97 L13.78,34.97 C15.374,30.844 19.312,28.032 23.998,28.032 L24,28.032
                                Z M16.968,22.032 C15.28,22.032 13.968,20.72 13.968,19.032 C13.968,17.344 15.28,16.032 16.968,16.032 C18.656,16.032 19.968,17.344
                                19.968,19.032 C19.968,20.72 18.656,22.032 16.968,22.032 Z M31.032,22.032 C29.344,22.032 28.032,20.72 28.032,19.032 C28.032,17.344
                                29.344,16.032 31.032,16.032 C32.72,16.032 34.032,17.344 34.032,19.032 C34.032,20.72 32.72,22.032 31.032,22.032 Z M24,40.032
                                C32.812,40.032 40.032,32.814 40.032,24 C40.032,15.186 32.814,7.968 24,7.968 C15.186,7.968 7.968,15.186 7.968,24 C7.968,32.814
                                15.186,40.032 24,40.032 Z M24,4.032 C35.062,4.032 43.968,12.938 43.968,24 C43.968,35.062 35.062,43.968 24,43.968 C12.938,43.968
                                4.032,35.062 4.032,24 C4.032,12.938 12.938,4.032 24,4.032 Z"
                  id="path-b"
                ></path>
              </svg>
              <br />
              <p>Bad</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="neutral"
              value="neutral"
              type="radio"
              name="feeling"
              formControlName="feeling"
            />
            <label id="neutral-l" for="neutral">
              <svg class="inline-block">
                <path
                  d="M24,40.032 C32.812,40.032 40.032,32.814 40.032,24 C40.032,15.186 32.814,7.968 24,7.968 C15.186,7.968 7.968,15.186 7.968,24
                                C7.968,32.814 15.186,40.032 24,40.032 Z M24,4.032 C35.062,4.032 43.968,12.938 43.968,24 C43.968,35.062 35.062,43.968 24,43.968
                                C12.938,43.968 4.032,35.062 4.032,24 C4.032,12.938 12.938,4.032 24,4.032 Z M13.968,19.032 C13.968,17.344 15.28,16.032 16.968,16.032
                                C18.656,16.032 19.968,17.344 19.968,19.032 C19.968,20.72 18.656,22.032 16.968,22.032 C15.28,22.032 13.968,20.72 13.968,19.032
                                Z M28.032,19.032 C28.032,17.344 29.344,16.032 31.032,16.032 C32.72,16.032 34.032,17.344 34.032,19.032 C34.032,20.72 32.72,22.032
                                31.032,22.032 C29.344,22.032 28.032,20.72 28.032,19.032 Z M18,28.032 L30,28.032 L30,31.032 L18,31.032 L18,28.032 Z"
                  id="path-n"
                ></path>
              </svg>
              <p>Neutral</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="good"
              value="good"
              type="radio"
              name="feeling"
              formControlName="feeling"
            />
            <label id="good-l" for="good">
              <svg class="inline-block">
                <path
                  d="M24,34.968 C19.312,34.968 15.374,32.156 13.782,28.03 L34.22,28.03 C32.626,32.156 28.688,34.968 24.002,34.968 L24,34.968
                                Z M16.968,22.032 C15.28,22.032 13.968,20.72 13.968,19.032 C13.968,17.344 15.28,16.032 16.968,16.032 C18.656,16.032 19.968,17.344
                                19.968,19.032 C19.968,20.72 18.656,22.032 16.968,22.032 Z M31.032,22.032 C29.344,22.032 28.032,20.72 28.032,19.032 C28.032,17.344
                                29.344,16.032 31.032,16.032 C32.72,16.032 34.032,17.344 34.032,19.032 C34.032,20.72 32.72,22.032 31.032,22.032 Z M24,40.032
                                C32.812,40.032 40.032,32.814 40.032,24 C40.032,15.186 32.814,7.968 24,7.968 C15.186,7.968 7.968,15.186 7.968,24 C7.968,32.814
                                15.186,40.032 24,40.032 Z M24,4.032 C35.062,4.032 43.968,12.938 43.968,24 C43.968,35.062 35.062,43.968 24,43.968 C12.938,43.968
                                4.032,35.062 4.032,24 C4.032,12.938 12.938,4.032 24,4.032 Z"
                  id="path-g"
                ></path>
              </svg>
              <p>Good</p>
            </label>
          </div>
        </div>
      </div>

      <div class="comments_div">
        <p>Please choose a category and give us your comments:</p>
        <div class="user_comment">
          <div class="selection">
            <input
              id="faq-support"
              value="faq&nbsp;support"
              type="radio"
              name="category"
              formControlName="category"
              required
            />
            <label for="faq-support">
              <p>FAQ / Support</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="get-started"
              value="get&nbsp;started"
              type="radio"
              name="category"
              formControlName="category"
            />
            <label for="get-started">
              <p>Get Started</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="api-catalog"
              value="api&nbsp;catalog"
              type="radio"
              name="category"
              formControlName="category"
            />
            <label for="api-catalog">
              <p>API Catalog</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="suggestion"
              value="suggestion"
              type="radio"
              name="category"
              formControlName="category"
            />
            <label for="suggestion">
              <p>Suggestion</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="compliment"
              value="compliment"
              type="radio"
              name="category"
              formControlName="category"
            />
            <label for="compliment">
              <p>Compliment</p>
            </label>
          </div>
          <div class="selection">
            <input
              id="other"
              value="other"
              type="radio"
              name="category"
              formControlName="category"
            />
            <label for="other">
              <p>Other</p>
            </label>
          </div>
        </div>
      </div>

      <textarea
        class="ta_comments"
        cols="60"
        rows="3"
        placeholder="Your comments here..."
        formControlName="userComment"
      ></textarea>
      <br />
      <button class="submit" [disabled]="!feedbackForm.valid">Submit</button>
      <br />
    </form>
  </div>
</div>
