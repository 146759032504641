import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ApplicationErrorComponent } from './shared/errors/application-error/application-error.component';
import { ApplicationErrorGuard } from './shared/errors/guard/application-error-guard';
import { RolesAuthGuardService } from './store/services/roles-auth-guard.service';
import { LoadingComponent } from './core/container-components/loading/loading.component';
import { ErrorPageComponent } from './shared/error-page/error-page.component';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./landing-page-redesign/landing-page-redesign.module').then(
        (m) => m.LandingPageRedesignModule
      ),
    data: {
      header: {
        isActivated: true,
      },
    },
  },
  {
    path: 'get-started',
    loadChildren: () =>
      import('./getting-started-page/getting-started-page.module').then(
        (m) => m.GettingStartedPageModule
      ),
  },
  {
    path: 'use-cases',
    loadChildren: () =>
      import('./use-cases-redesign/use-cases-redesign.module').then(
        (m) => m.UseCasesRedesignModule
      ),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'support',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'apps',
    canLoad: [RolesAuthGuardService],
    loadChildren: () =>
      import('./app-key-management/app-key-management.module').then(
        (m) => m.AppKeyManagementModule
      ),
  },
  // {
  //   path: 'open-api',
  //   loadChildren: () => import('@developer-portal/open-api-documentation').then(m => m.OpenAPIDocumentationModule)
  // },
  {
    path: 'loading',
    component: LoadingComponent,
  },
  {
    path: 'error',
    component: ApplicationErrorComponent,
    canActivate: [ApplicationErrorGuard],
  },
  {
    path: 'resource-not-found',
    component: ErrorPageComponent,
    canActivate: [ApplicationErrorGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled'
      /**
       * Enabled scrollPositionRestoration feature to remove window.scroll to top of the page
       */
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
