import { PostFeedback } from './../../models/post-feedback.model';
import { Action } from '@ngrx/store';
import { HttpError } from '../../../models/http-error.model';

export enum PostFeedbackActionTypes {
  PostFeedbackLoad = '[Post Feedback] PostFeedback loaded',
  PostFeedbackSuccess = '[Post Feedback] PostFeedback Success',
  PostFeedbackFail = '[Post Feedback] PostFeedback Fail',
  ClearPostFeedback = '[Post Feedback] Clean PostFeedback',
}

export class PostFeedbackLoad implements Action {
  readonly type = PostFeedbackActionTypes.PostFeedbackLoad;
  constructor(public payload: {}) {}
}

export class PostFeedbackSuccess implements Action {
  readonly type = PostFeedbackActionTypes.PostFeedbackSuccess;
  constructor(public payload: {}) {}
}

export class PostFeedbackFail implements Action {
  readonly type = PostFeedbackActionTypes.PostFeedbackFail;
  constructor(public payload: HttpError) {}
}

export class ClearPostFeedback implements Action {
  readonly type = PostFeedbackActionTypes.ClearPostFeedback;
  constructor() {}
}

export type PostFeedbackActionsUnion =
  | PostFeedbackLoad
  | PostFeedbackSuccess
  | PostFeedbackFail
  | ClearPostFeedback;
