import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VERSION } from '../environments/version';
import { PageTitles } from './core/page-titles.enums';
import { Util } from './core/util';
import { BannerInfo } from './models/maintenance-banner.model';
import { Menu } from './models/menu';

import * as BannerActions from './store/actions/banner.actions';
import * as maintenanceBannerCms from './store/actions/maintenance-banner.action';
import * as fromApp from './store/reducers';
import * as fromError from './store/reducers/index';

import { GoogleAnalyticsService } from './store/services/google-analytics.service';
import { PingService } from './store/services/ping.service';
import { Session, UserDetails } from '@developer-portal/shared/models';
import * as FromSharedUser from '@developer-portal/shared/data-access/user';
import { SessionOrchestratorService } from '@developer-portal/shared/data-access/user';
import { UserConstants } from '@developer-portal/shared/constants';
import { environment } from '../environments/environment';


@Component({
  selector: 'developer-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public siteName = 'Developer Portal';
  public userMenu: Menu[];
  public landingPageMenu: Menu[];
  public role: {
    isOrgAdmin: boolean;
    isTsp: boolean;
    isInternalUser: boolean;
    isCorporateAdministrator: boolean;
    isUserAdministrator: boolean;
  };
  public landingFooter = 'landingFooter';
  public applicationFooter = 'applicationFooter';
  public isSessionActive = false;
  public activeUserSplitName: string;
  public showBrowserMessage = true;
  public userLoaded$: Observable<any>;
  public appError: boolean;
  public hasGlobalError = false;
  public errorStatusText: string;
  public errorStatus: string;
  public Util: Util;
  public siteKey: string = Util.GOOGLE_SITE_KEY;
  public loginHover = false;
  public activeUserName: string;
  public loadSpinner$: Observable<boolean>;
  public isInternalUserAppGenActive: boolean;
  public routeTechSupport: boolean;
  public isBannerListLoaded$: Observable<boolean>;

  public readonly LOG_IN: string = 'Log In';
  public readonly LOG_OUT: string = 'Log Out';
  public maintenanceCmsContent$: Observable<any>;
  public isMaintenanceCmsLoaded$: Observable<boolean>;
  public maintenanceBannerInfo: BannerInfo;
  public showBanner = true;
  public isPublicLogin: boolean;
  public publicClearCDXSession: boolean;

  constructor(
    private store: Store<fromApp.State>,
    public router: Router,
    private route: ActivatedRoute,
    private pingService: PingService,
    public sessionOrchestratorService: SessionOrchestratorService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title
  ) {
    this.userLoaded$ = store.pipe(select(FromSharedUser.userLoaded));
    this.Util = new Util();
    this.googleAnalyticsService.loadGoogleAnalyticsScript();
    this.isMaintenanceCmsLoaded$ = store.select(
      fromApp.selectMbannerContentLoaded
    );
    this.maintenanceCmsContent$ = store.select(
      fromApp.selectMbannerContentData
    );
    this.isBannerListLoaded$ = store.select(fromApp.selectBannerListLoaded);
  }

  ngOnInit() {
    this.addDynatraceMonitering();
    this.titleService.setTitle(PageTitles.Homepage);
    this.userMenu = this.assignUserMenu();
    this.determineUserLoginType();
    this.sessionOrchestratorService.getUserDetails(this.isPublicLogin, this.publicClearCDXSession);
    this.browserMessageTimer();
    this.printVersionInfo();
    this.loadMaintenanceBannerCms();
    this.getMaintenanceBannerCms();
    this.getBannerInfoFromCMS();
    this.onCurrentUser();
  }

  determineUserLoginType(){
    const env:string = this.pingService.getEnvironmentContext();
    if(env['authPrefix'] == 'fv7ui-pubauth'){
      this.isPublicLogin = true;
      let querystring = window.location.search.substring(1);
      this.publicClearCDXSession = (querystring == 'clear_cdx_session=true') ? true : false;
    }
    else{
      this.isPublicLogin = false;
    }
  }

  getBannerInfoFromCMS() {
    this.isBannerListLoaded$.pipe().subscribe((loaded: Boolean) => {
      if (!loaded) {
        this.store.dispatch(BannerActions.LoadAllBannerInfo());
      }
    });
  }

  printVersionInfo() {
    console.table(VERSION);
  }

  addDynatraceMonitering(): void {
    const script = document.createElement('script');
    let dynatraceUrl = '';
    Object.keys(environment.pingEnvironmentUrls).forEach((env, i) => {
      if (env === window.location.host) {
        dynatraceUrl = environment.pingEnvironmentUrls[env]['dynatraceUrl'];
      }
    });

    script.src = dynatraceUrl;
    script.async = true;
    script.crossOrigin = 'anonymous';
    script.defer = true;
    document.body.appendChild(script);
  }

  ngAfterViewInit() {
    this.observeGlobalErrorState();
    this.gaRouterEventListener();
  }

  loadMaintenanceBannerCms() {
    this.isMaintenanceCmsLoaded$.pipe().subscribe((loaded: boolean) => {
      this.store.dispatch(maintenanceBannerCms.LoadMaintenanceBannerContent());
    });
  }

  getMaintenanceBannerCms() {
    this.isMaintenanceCmsLoaded$.pipe().subscribe((loaded: boolean) => {
      if (loaded) {
        this.maintenanceCmsContent$.subscribe((response) => {
          this.maintenanceBannerInfo = response;
        });
      }
    });
  }

  closeBanner() {
    this.showBanner = false;
  }

  browserMessageTimer() {
    setTimeout(() => {
      this.showBrowserMessage = false;
    }, 10000);
  }

  assignUserMenu(): Menu[] {
    const menus: Menu[] = [
      {
        title: 'Get started',
        url: 'get-started',
        isExternalLink: false,
        showMobileSubNav: false,
        subNav: [
          {
            title: 'User guide',
            url: 'user-guide',
          },
          {
            title: 'Release notes',
            url: 'release-notes',
          },
        ],
      },
      {
        title: 'APIs',
        url: 'products/api',
        isExternalLink: false,
      },
      {
        title: 'Use cases',
        url: 'use-cases',
        isExternalLink: false,
      },
      {
        title: 'Support',
        url: 'support',
        isExternalLink: false,
      },
      {
        title: this.LOG_IN,
        url: 'login',
        isExternalLink: true,
      },
    ];
    return menus;
  }


  updateUserMenuWithDashboard(menus: Menu[], route: string): Menu[] {
    const menuMyDashboard: Menu = {
      title: 'My dashboard',
      url: route,
      isExternalLink: false,
    };

    let indexOfLogOut = -1;

    menus.map((item) => {
      if (item.title === this.LOG_OUT) {
        indexOfLogOut = menus.indexOf(item);
      }
    });

    if (indexOfLogOut >= 0) {
      menus.splice(indexOfLogOut, 0, menuMyDashboard);
    }

    return menus;
  }

  updateUserMenuWithLogout(menus: Menu[]): Menu[] {
    const logoutUrlKey = 'logoutUrl';
    const targetResourceKey = 'targetResource';
    const logoutUrl: string =
      this.pingService.getEnvironmentContext()[logoutUrlKey] +
      this.pingService.getEnvironmentContext()[targetResourceKey];
    const userMenu: Menu = {
      title: this.LOG_OUT,
      url: logoutUrl,
      isExternalLink: true,
    };

    const mMenus: Menu[] = menus.filter((menu: Menu) => {
      return menu.title !== this.LOG_IN;
    });

    mMenus.push(userMenu);

    return mMenus;
  }

  onUserMenuClickEventEmitter(menu: Menu) {
    if (menu.title === this.LOG_IN) {
      this.onLoginClick();
    }

    if (menu.title === this.LOG_OUT) {
      this.onLogoutClick();
    }
  }

  onLoginClick(): void {
    window.localStorage.setItem('x-fnma-first-time-login', 'true');
    setTimeout(() => {
      window.location.href = this.pingService.pingLoginUrl();
    }, 300);
  }

  onLogoutClick(): void {
    this.sessionOrchestratorService.clearSession();
    window.location.href = this.pingService.pingLogOutUrl();
  }

  onCurrentUser() {
    const currentUser$: Observable<any> = this.store.select(
      FromSharedUser.getCurrentUser
    );
    const isInternalKeyGenActive$: Observable<boolean> = this.store.select(
      FromSharedUser.selectIsInternalUserLoginEnabled
    );
    let hasAuthorizedRole = false;
    isInternalKeyGenActive$.subscribe((appGenActive) => {
      if (appGenActive !== undefined) {
        this.isInternalUserAppGenActive = appGenActive;
      }
    });

    currentUser$.subscribe(
      (currentUser: { user: UserDetails; session: Session }) => {
        console.log(':::currentUser:::', currentUser);
        if (currentUser.session.active) {
          this.userMenu = this.updateUserMenuWithLogout(this.userMenu);
          this.isSessionActive = currentUser.session.active;
          this.activeUserSplitName =
            currentUser.user.firstName[0] + currentUser.user.lastName[0];
          this.activeUserName = currentUser.user.firstName;
          window['dynatraceUserName'] = currentUser.user.userName;

          if (currentUser.user.groups.length > 0) {
            const isFannieMaeUser: boolean =
              currentUser.user?.isFannieMaeUser ||
              Util.isBoolean(currentUser.user?.isFannieMaeUser);

            hasAuthorizedRole =
              Util.hasRole(currentUser, {
                role: { type: UserConstants.APIPORT_DEVPORTAL_TSP },
              }) ||
              Util.hasRole(currentUser, {
                role: { type: UserConstants.APIPORT_DEVPORTAL_ORG_ADMIN },
              }) ||
              Util.hasRole(currentUser, {
                role: { type: UserConstants.FLEX_EXTERNAL_CUSTOMER },
              }) ||
              Util.hasRole(currentUser, {
                role: { type: UserConstants.FLEX_INTERNAL_USER },
              }) ||
              Util.hasRole(currentUser, {
                role: { type: UserConstants.FLEX_LEGAL_REVIEWER },
              }) ||
              Util.hasRole(currentUser, {
                role: { type: UserConstants.FLEX_TECH_ASSET_OWNER },
              }) ||
              (isFannieMaeUser && this.isInternalUserAppGenActive);
            if (hasAuthorizedRole) {
              this.userMenu = this.updateUserMenuWithDashboard(
                this.userMenu, 'apps'
              );
              this.onCurrentUserNavigation();
            }
          } else if(currentUser.user.iss.startsWith(UserConstants.PUBLIC)) {
            this.userMenu = this.updateUserMenuWithDashboard(
              this.userMenu, 'apps'
            );
            this.onCurrentUserNavigation();
          }
        }
      }
    );
  }

  onCurrentUserNavigateToDashboard(): void {
    const canNavigate: boolean =
      (<any>window).localStorage.getItem('x-fnma-first-time-login') === 'true';
    const firstTimeLogin: boolean =
      (<any>window).localStorage.getItem('x-fnma-first-time-login') === null;
    if (canNavigate || firstTimeLogin) {
      this.router.navigate(['/apps/dashboard']).then((didNavigate: boolean) => {
        if (didNavigate) {
          (<any>window).localStorage.setItem(
            'x-fnma-first-time-login',
            'false'
          );
        }
      });
    }
  }

  onCurrentUserNavigation(): void {
    const docName: string = (<any>window).localStorage.getItem(
      'x-fnma-documentation-name'
    );
    if (docName) {
      this.router
        .navigate(['/products/api/documentation' + '/' + docName])
        .then((didNavigate: boolean) => {
          if (didNavigate) {
            (<any>window).localStorage.removeItem('x-fnma-documentation-name');
          }
        });
    } else {
      this.onCurrentUserNavigateToDashboard();
    }
  }

  observeGlobalErrorState() {
    this.store
      .pipe(select(fromError.getGlobalErrorState))
      .subscribe((errorState) => {
        if (errorState !== null) {
          if (errorState.isGlobalError) {
            this.hasGlobalError = true;
            this.errorStatusText = errorState.statusText;
            this.errorStatus = errorState.status;
            this.routeTechSupport = errorState.routeTechSupport;
          }
        }
      });
  }

  closeGlobalErrorComponent(isGlobalErrorActive) {
    if (Util.isBoolean(isGlobalErrorActive)) {
      this.hasGlobalError = !isGlobalErrorActive;
    } else {
      this.router.navigate([isGlobalErrorActive.url]);
    }
  }

  gaRouterEventListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.logNavigationEvent(event);
      }
    });
  }
}
