import { Action } from '@ngrx/store';
import { HttpError } from '@developer-portal/shared/models';
import { UserDetails } from '@developer-portal/shared/models';
import { createAction, props } from '@ngrx/store';

export const LoadUser = createAction(
  '[Login] Load User',
  props<{isPublicLogin: boolean, clearCDXSession: boolean}>()
);

export const LoadSuccess  = createAction(
  '[Login] Load User Success',
  props<{user: Partial<UserDetails>}>()
);

export const LoadFail  = createAction(
  '[Login] Load User Fail',
  props<{error: HttpError}>()
);

export const ClearUserSession  = createAction(
  '[Login] Clear User Session'
);

