<div class="notification-container">
  <img class="notification-status-icon" src="./assets/icons/alert.svg" />
  {{ statusText }}
  <span *ngIf="routeTechSupport == true">
    &nbsp;Please contact
    <a [routerLink]="['/support']" class="tech-support-link">Tech Support.</a>
  </span>
  <ng-container>
    <button
      #closeButton
      class="notification-close"
      *ngIf="status !== 'browserMessage'"
      (click)="closeNotification()"
    >
      Close
    </button>
  </ng-container>
  <!-- <ng-container *ngIf="routeTechSupport == true">
    <button class="notification-close" *ngIf="status !== 'browserMessage'" (click)="routeToTechSupport()"><u>Tech Support</u></button>
  </ng-container> -->
</div>
