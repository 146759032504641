import { AppActionUnion, AppActionTypes } from '../actions/app.actions';

export interface State {
  spinner: boolean;
}

export const initialState: State = {
  spinner: false,
};

export function reducer(state = initialState, action: AppActionUnion): State {
  switch (action.type) {
    case AppActionTypes.Spinner: {
      return {
        spinner: action.payload,
      };
    }
    default:
      return state;
  }
}

export const getSpinner = (state: State) => state.spinner;
