import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import * as fromError from './error.reducer';
import * as fromApp from './app.reducer';
import * as fromMaintenaceBannerReducer from './maintenance-banner.reducer';
import * as fromBannerListReducer from './banner.reducer';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
export interface AppState {
  appState: fromApp.State;
}

export interface ErrorState {
  errorState: fromError.State;
}

export interface MaintenanceBanneCmsState {
  bannerCms: fromMaintenaceBannerReducer.State;
}

export interface CmsContentState {
  cmsContentState: fromMaintenaceBannerReducer.State;
}

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  app: null;
  error: null;
  bannerCms: null;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const appReducer: ActionReducerMap<State> = {
  app: null,
  error: null,
  bannerCms: null,
};

export const mAppReducer: ActionReducerMap<AppState> = {
  appState: fromApp.reducer,
};

export const errorReducer: ActionReducerMap<ErrorState> = {
  errorState: fromError.reducer,
};

export const createCmsContentReducer: ActionReducerMap<CmsContentState> = {
  cmsContentState: fromMaintenaceBannerReducer.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    // console.log(':::state:::', state);
    // console.log(':::action:::', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [logger];

// let stateFeatureKey: keyof State;
export const errorKey = 'Error';
export const appKey = 'App';
export const bannerKey = 'MaintenanceBannerCMS';

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectErrorState = createFeatureSelector<ErrorState>(errorKey);

export const selectAppState = createFeatureSelector<AppState>(appKey);
/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const getAppState = createSelector(
  selectAppState,
  (state: AppState) => state.appState
);

export const getSpinner = createSelector(getAppState, fromApp.getSpinner);

export const getErrorState = createSelector(
  selectErrorState,
  (state: ErrorState) => state.errorState
);

export const getGlobalErrorState = createSelector(
  getErrorState,
  fromError.getError
);

export const selectBannerCmsContentState =
  createFeatureSelector<CmsContentState>(bannerKey);

export const getBannerCmsContentState = createSelector(
  selectBannerCmsContentState,
  (state: CmsContentState) => state.cmsContentState
);

export const selectMbannerContentLoaded = createSelector(
  getBannerCmsContentState,
  fromMaintenaceBannerReducer.isMaintenanceBannerLoaded
);

export const selectMbannerContentData = createSelector(
  getBannerCmsContentState,
  fromMaintenaceBannerReducer.getMaintenanceBannerContent
);

/*
For banner
*/

export const featureKey = 'bannerList';

//get state from the reducer
export interface BannerListState {
  bannerListState: fromBannerListReducer.State;
}

//define reducer
export const createbannerListReducer: ActionReducerMap<BannerListState> = {
  bannerListState: fromBannerListReducer.reducer,
};

//Feature Selector to return top level feature state
export const selectBannerListState =
  createFeatureSelector<BannerListState>(featureKey);

//get state from the Feature Selector
export const getBannerListState = createSelector(
  selectBannerListState,
  (state: BannerListState) => state.bannerListState
);

//select specific parts of the feature state using the selector functions exported by the reducer
export const selectBannerListLoaded = createSelector(
  getBannerListState,
  fromBannerListReducer.isBannerListLoaded
);

export const selectBannerList = createSelector(
  getBannerListState,
  fromBannerListReducer.getBannerList
);
