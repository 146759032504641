import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '../services/user.service';
import { UserDetails } from '@developer-portal/shared/models';
import * as UserActions from '../+state/user.actions';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpError } from '@developer-portal/shared/models';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}

  loadUser$ = createEffect(() => 
    this.actions$.pipe(
      ofType(UserActions.LoadUser),
      switchMap((action) => 
        this.userService.userDetails(action.isPublicLogin, action.clearCDXSession).pipe(
          map((response: UserDetails) => response['userDetails']),
          map((response) => ({
            type: '[Login] Load User Success',
            user: response
          })),
          catchError((error) => 
            of({
              type: '[Login] Load User Fail',
              err: error,
            })
          )
        )
      )
    )
  );

  loadUserFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LoadFail),
      tap(() => window.localStorage.clear()),
      map(() => ({type: '[Login] Clear User Session'}))
    )
  );

  
}
