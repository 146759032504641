<footer class="footer">
  <ng-container *ngIf="siteFooter === 'landingFooter'">
    <div class="sub-footer">
      <div class="sub-footer__content">
        <div class="footer-social-links">
          <a
            href="https://www.linkedin.com/company/fannie-mae-corporation_19722/"
            target="_blank"
          >
            <img src="./assets/landing-page/Linkedin.svg" alt="linkedin" />
          </a>
          <a href="https://www.facebook.com/fanniemae" target="_blank">
            <img src="./assets/landing-page/Facebook.svg" alt="facebook" />
          </a>
          <a href="https://twitter.com/FannieMae" target="_blank">
            <img src="./assets/landing-page/Twitter.svg" alt="twitter" />
          </a>
        </div>
        <div class="copyright-text">
          <div class="sub-footer-links-content">
            <a href="https://www.fanniemae.com/careers" target="_blank"
              >Careers</a
            >
            <a
              href="http://www.fanniemae.com/portal/privacy-statement.html"
              target="_blank"
              >Privacy</a
            >
            <a
              href="http://www.fanniemae.com/portal/legal-disclosure.html"
              target="_blank"
              >Legal</a
            >
          </div>
          <div class="desktop-pipe-image">
            <img src="./assets/landing-page-redesign/footer_sep_line.svg" />
          </div>
          <span>{{ copyrightContent }}</span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="siteFooter === 'applicationFooter'">
    <div class="app-sub-footer">
      <div class="sub-footer__content">
        <!-- <img src="../../../../assets/landing-page/fm_logo.svg" class="sub-footer__logo-image"> -->
        <div class="sub-footer-links-content">
          <div class="sub-footer-links">
            <a href="#home" class="sub-footer__link">Home</a>
            <a href="#get-started" class="sub-footer__link">Get Started</a>
          </div>
          <div class="sub-footer-links">
            <a href="#products/catalog" class="sub-footer__link">API Catalog</a>
            <a href="#faq" class="sub-footer__link">FAQ</a>
            <a href="#support" class="sub-footer__link">Support</a>
          </div>
        </div>
        <div class="copyright-text">{{ copyrightContent }}</div>
      </div>
    </div>
    <!-- <div class="copyright">

      </div> -->
  </ng-container>
</footer>
