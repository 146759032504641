import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class PingService {
  constructor() {}

  public getEnvironmentContext(): string {
    let loginContext: string;
    const origin = window.location.host;

    if (environment.pingEnvironmentUrls[origin] !== undefined) {
      loginContext = environment.pingEnvironmentUrls[origin];
    } else {
      loginContext = '';
    }

    return loginContext;
  }

  public pingLoginUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['loginUrl'];
    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingPubLoginUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['pingLoginUrl'];

    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingSignupUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLoginContext: string = environmentContext['registerUrl'];

    if (pingLoginContext !== undefined || pingLoginContext !== null) {
      return pingLoginContext;
    }

    return '#';
  }

  public pingLogOutUrl(): string {
    const environmentContext: string = this.getEnvironmentContext();
    const pingLogoutContext: string =
      environmentContext['logoutUrl'] + environmentContext['targetResource'];

    return pingLogoutContext;
  }
}
