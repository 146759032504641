import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const loadNoAuthFeatureToggle = createAction(
  '[FeatureToggle/API] Load No Auth FeatureToggle'
);

export const loadNoAuthFeatureToggleSuccess = createAction(
  '[FeatureToggle/API] Load No Auth FeatureToggle Success',
  props<{ featureToggle: any }>()
);

export const loadNoAuthFeatureToggleFail = createAction(
  '[FeatureToggle/API] Load No Auth FeatureToggle Fail',
  props<{ error: HttpErrorResponse }>()
);

export const LoadAuthFeatureToggle = createAction(
  '[FeatureToggle/API] Load Auth Feature Toggle'
);

export const LoadAuthFeatureToggleSuccess = createAction(
  '[FeatureToggle/API] Load Auth Feature Toggle Success',
  props<{ featureToggle: any }>()
);

export const LoadAuthFeatureToggleFail = createAction(
  '[Auth Code Feature Toggle] Load Auth Code Feature Toggle Fail',
  props<{ error: HttpErrorResponse }>()
);

export const cleanFeatureToggle = createAction(
  '[FeatureToggle/API] Load FeatureToggle Clean'
);
