import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { HttpPostFeedbackService } from '../services/http-post-feedback.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import {
  PostFeedbackActionTypes,
  PostFeedbackSuccess,
  PostFeedbackLoad,
  PostFeedbackFail,
} from '../actions/post-feedback.action';
import { HttpError } from './../../../models/http-error.model';

@Injectable()
export class PostFeedbackEffects {
  constructor(
    private actions$: Actions,
    private httpPostFeedbackService: HttpPostFeedbackService
  ) {}

  @Effect()
  postFeedback$: Observable<Action> = this.actions$.pipe(
    ofType<any>(PostFeedbackActionTypes.PostFeedbackLoad),
    map((action: PostFeedbackLoad) => action.payload),
    mergeMap((action: any) => {
      return this.httpPostFeedbackService.postFeedback(action).pipe(
        map((response: any) => new PostFeedbackSuccess(response)),
        catchError((error: HttpError) => of(new PostFeedbackFail(error)))
      );
    })
  );
}
