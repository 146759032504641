import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './+state/user.effects';
import { SessionOrchestratorService } from './services/session-orchestrator.service';
import { UserService } from './services/user.service';
import * as fromUserReducer from './+state/index';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('user', fromUserReducer.createUserReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [UserService, SessionOrchestratorService],
})
export class SharedDataAccessUserModule {}
