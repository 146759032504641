import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'dp-application-global-error',
  templateUrl: './application-global-error.component.html',
  styleUrls: ['./application-global-error.component.scss'],
})
export class ApplicationGlobalErrorComponent implements OnInit, AfterViewInit {
  @Input()
  statusText;
  @Input()
  status;
  @Input() routeTechSupport: boolean;
  @Output()
  closeErrorAlert: EventEmitter<any> = new EventEmitter();
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;

  constructor(el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  closeNotification() {
    //this.closeButton.nativeElement.parentElement.parentElement.style.display = 'none';
    this.closeErrorAlert.emit(true);
  }

  routeToTechSupport() {
    this.closeErrorAlert.emit({ url: '/support' });
  }
}
