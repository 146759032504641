<section
  class="
    text-left
    w-full
    flex
    justify-center
    h-40
    md:h-48
    relative
    hero-banner-container
  "
>
  <img
    class="w-full h-auto absolute banner_bg h-40 md:h-48"
    [src]="backgroundUrl"
    alt="catalog_banner"
  />
  <div class="hero-banner-info pl-4">
    <img [src]="headerIconUrl" class="h-24 w-24" />
    <div class="pl-4">
      <h2
        class="
          font-sans font-semibold
          inline-block
          text-header-xs text-header-blue
          md:text-4xl
        "
      >
        {{ title }}
      </h2>
      <p
        class="
          font-sans font-normal
          text-base
          md:text-lg
          m-0
          text-left text-black
          pt-1
          pr-4
        "
      >
        {{ description }}
      </p>
    </div>
  </div>
</section>
