import { UserConstants } from '@developer-portal/shared/constants';
import * as fromUserReducer from './user.reducer';

import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import { Session, UserDetails } from '@developer-portal/shared/models';

export interface UserState {
  userProfile: fromUserReducer.State;
}

export const createUserReducer: ActionReducerMap<UserState> = {
    userProfile: fromUserReducer.reducer
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectUserState = createFeatureSelector<UserState>('user');
/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const getCurrentUserProfile = createSelector(
  selectUserState,
  (state: UserState) => state.userProfile
);

export const selectUser = createSelector(
  getCurrentUserProfile,
  fromUserReducer.getUser
);

export const selectSession = createSelector(
  getCurrentUserProfile,
  fromUserReducer.getSession
);

export const userLoaded = createSelector(
  getCurrentUserProfile,
  fromUserReducer.getLoaded
);

export const getCurrentUser = createSelector(
  selectUser,
  selectSession,
  (user: Partial<UserDetails>, session: Session) => {
    return { user: user, session: session };
  }
);

export const getCaptchaValue = createSelector(getCurrentUser, fromUserReducer.getUser);

export const selectIsInternalUserLoginEnabled = createSelector(
  getCurrentUserProfile,
  fromUserReducer.getIsInternalUserLoginEnabled
);

export const isSessionActive = createSelector(
  getCurrentUser,
  (currentUser: { user: UserDetails; session: Session }) => {
    if (currentUser?.session !== null) {
      return currentUser.session?.active;
    }
  }
);

export const isOrgAdminRole = createSelector(
  getCurrentUser,
  (currentUser: { user: UserDetails; session: Session }) => {
    if (currentUser?.session != null) {
      const session: Session = currentUser.session;

      if (session.active) {
        const groups: string[] = currentUser.user.groups;

        if (groups?.length > 0) {
          return groups.find(
            (role: string) => role === UserConstants.APIPORT_DEVPORTAL_ORG_ADMIN
          );
        }
      }
    }
  }
);

export const isTSPRole = createSelector(
  getCurrentUser,
  (currentUser: { user: UserDetails; session: Session }) => {
    if (currentUser?.session != null) {
      const session: Session = currentUser.session;

      if (session.active) {
        const groups: string[] = currentUser.user.groups;

        if (groups?.length > 0) {
          return groups.find(
            (role: string) => role === UserConstants.APIPORT_DEVPORTAL_TSP
          );
        }
      }
    }
  }
);

export const userRole = createSelector(
  getCurrentUser,
  (currentUser: { user: UserDetails; session: Session }) => {
    if (currentUser?.session != null) {
      const session: Session = currentUser.session;
      if (session.active) {
        const groups: string[] = currentUser.user.groups;

        if (groups?.length > 0) {
          return groups.find(
            (role: string) =>
              role === UserConstants.APIPORT_DEVPORTAL_TSP ||
              role === UserConstants.APIPORT_DEVPORTAL_ORG_ADMIN
          );
        }
      }
    }
  }
);
