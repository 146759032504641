import { Action, createReducer, on } from '@ngrx/store';
import * as MaintenanceBannerActions from '../actions/maintenance-banner.action';
import { HttpError } from '../../models/http-error.model';
import { BannerInfo } from '../../models/maintenance-banner.model';

export interface State {
  loaded: boolean;
  bannerData: BannerInfo;
  error: HttpError;
}

export const initialState: State = {
  loaded: false,
  bannerData: null,
  error: null,
};

const maintenanceBannerReducer = createReducer(
  initialState,
  on(MaintenanceBannerActions.LoadMaintenanceBannerContent, (state) => ({
    ...state,
  })),

  on(
    MaintenanceBannerActions.LoadMaintenanceBannerContentSuccess,
    (state, { data }) => ({
      ...state,
      loaded: true,
      bannerData: data,
    })
  ),

  on(
    MaintenanceBannerActions.LoadMaintenanceBannerContentFail,
    (state, { err }) => ({
      ...state,
      loaded: true,
      error: err,
    })
  ),

  on(MaintenanceBannerActions.ClearMaintenanceBannerContent, (state) => ({
    ...state,
    loaded: false,
    bannerData: null,
    error: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return maintenanceBannerReducer(state, action);
}

export const isMaintenanceBannerLoaded = (state: State) => state.loaded;
export const getMaintenanceBannerContent = (state: State) => state.bannerData;
export const getMaintenanceBannerErrorStatus = (state: State) => state.error;
