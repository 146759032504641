import {
  UserDetails,
  Session,
  RoleType,
} from '@developer-portal/shared/models';

export class Util {
  public static readonly GOOGLE_SITE_KEY =
    '6LeoADYUAAAAADHBvtyT7gkd6uToMt9y5Ju3PWLN';

  constructor() {}

  public static hasRole(
    currentUser: { user: UserDetails; session: Session },
    roleType: { role: RoleType }
  ): boolean {
    let hasRole = false;

    if (currentUser.user !== null) {
      for (const role of currentUser.user.groups) {
        if (role === roleType.role.type) {
          hasRole = true;
          break;
        }
      }
    }

    return hasRole;
  }

  public static isNullOrUndefined<T>(
    object: T | undefined | null
  ): object is T {
    return <T>object !== undefined && <T>object !== null;
  }

  public static isBoolean(object: any): boolean {
    return typeof object === 'boolean';
  }
}
