import { Directive, ElementRef, HostListener } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[dpHeaderSpyScroll]',
})
export class HeaderSpyScrollDirective {
  constructor(private el: ElementRef, private router: Router) {
    this.globalNavigationRouterEvents();
  }

  public isHomePage: boolean;

  @HostListener('window:scroll') onWindowScroll() {
    if (this.isHomePage && window.pageYOffset < 80) {
      this.el.nativeElement.classList.remove('dark-bg');
    } else if (this.router.url === '/resource-not-found') {
      this.el.nativeElement.classList.remove('dark-bg');
    } else {
      this.el.nativeElement.classList.add('dark-bg');
    }
  }

  globalNavigationRouterEvents(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        if (event.url === '/home') {
          if (window.pageYOffset < 80) {
            this.el.nativeElement.classList.remove('dark-bg');
          }
          this.isHomePage = true;
        }
        if (event.url === '/resource-not-found') {
          this.el.nativeElement.classList.remove('dark-bg');
          this.isHomePage = false;
        } else {
          this.isHomePage = false;
          this.el.nativeElement.classList.add('dark-bg');
        }
      });
  }
}
