import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import * as BannerActions from '../actions/banner.actions';
import { BannerService } from '../services/banner.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BannerEffects {
  constructor(
    private actions$: Actions,
    private bannerService: BannerService
  ) {}

  loadAllBannerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BannerActions.LoadAllBannerInfo),
      switchMap(() =>
        this.bannerService.getAllBannerInfoV1().pipe(
          map((response) => ({
            type: '[App] Load All Banner Info Success',
            data: response,
          })),
          catchError((error) =>
            of({ type: '[App] Load All Banner Info Fail', err: error })
          )
        )
      )
    )
  );
}
