<div
  class="
    banner-container
    bg-bright-blue
    absolute
    z-10
    top-14
    w-full
    flex
    justify-center
  "
  *ngFor="let info of maintenanceBannerInfo"
>
  <div
    *ngIf="maintenanceBannerInfo"
    class="m-0 text-white maintenance-info flex flex-col items-start relative"
  >
    <img
      src="../../assets/icons/Info_Icon_White.svg"
      alt="info-icon"
      class="inline-block pt-1 pr-4 absolute"
    />
    <div
      class="
        text-label text-sm
        xl:text-xl
        pl-7
        xl:pl-8
        pr-1
        leading-6
        font-semibold
        whitespace-normal
        md:whitespace-nowrap
      "
    >
      {{ info.field_maintenance_banner_title }}
    </div>
    <div class="pl-8 pr-5 text-xs xl:text-base">
      {{ info.field_maintenance_banner_descrip }}
    </div>
    <img
      src="../../assets/images/Close_icon.svg"
      alt="close-icon"
      class="inline-block absolute right-4 top-4 xl:top-5"
      (click)="onCloseClick()"
    />
  </div>
</div>
