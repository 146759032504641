import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
} from '@angular/core';

@Directive({
  selector: '[dpGoogleRecaptcha]',
})
export class GoogleRecaptchaDirective implements AfterViewInit, OnDestroy {
  @Input() siteKey: string = null;

  @Input() executeOnClick: boolean = false;

  @Input() theme = 'light';

  @Input() type = 'image';

  @Input() size = 'invisible';

  @Input() tabindex = 0;

  @Input() initCallback = 'initRecaptcha';

  @Output() responseEventEmitter: EventEmitter<any> = new EventEmitter();

  @Output() expireEventEmitter: EventEmitter<any> = new EventEmitter();

  public _instance: any = null;

  constructor(public el: ElementRef, public _zone: NgZone) {}

  initialize() {
    this._instance = (<any>window).grecaptcha.render(this.el.nativeElement, {
      sitekey: this.siteKey,
      theme: this.theme,
      type: this.type,
      size: this.size,
      tabindex: this.tabindex,
      callback: (response: string) => {
        this._zone.run(() => this.recaptchaCallback(response));
      },
      'expired-callback': () => {
        this._zone.run(() => this.recaptchaExpiredCallback());
      },
    });
  }

  executeCheck() {
    (<any>window).grecaptcha.execute(this._instance);
  }

  ngAfterViewInit() {
    if ((<any>window).grecaptcha) {
      this.initialize();
    } else {
      (<any>window)[this.initCallback] = () => {
        this.initialize();
      };
    }
  }

  @HostListener('click', ['$event']) onClick($event) {
    if (this.executeOnClick) {
      this.executeCheck();
    }
  }

  reset() {
    if (this._instance === null) {
      return;
    }

    (<any>window).grecaptcha.reset(this._instance);
  }

  getResponse(): String {
    if (this._instance === null) {
      return null;
    }

    return (<any>window).grecaptcha.getResponse(this._instance);
  }

  recaptchaCallback(response: string) {
    this.responseEventEmitter.emit({
      response: response,
    });
  }

  recaptchaExpiredCallback() {
    this.expireEventEmitter.emit();
  }

  ngOnDestroy() {
    if (this._instance != null) {
      (<any>window).grecaptcha.reset(this._instance);
    }
  }
}
