import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dpLandingHeaderScroll]',
})
export class LandingHeaderScrollDirective {
  constructor(private el: ElementRef) {}

  @HostListener('window:scroll') onWindowScroll() {
    if (window.pageYOffset > 240) {
      this.hideNav('none');
    } else {
      this.hideNav('flex');
    }
  }

  private hideNav(display: string) {
    this.el.nativeElement.style.display = display;
  }
}
