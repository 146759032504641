import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from '../../../models/menu';

/**
 * application header component
 * This component serves as the application header for both desktop full size navigation and mobile version drop down menus.
 */
@Component({
  selector: 'dp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() loginHover: boolean;
  @Input() siteName: string;
  @Input() siteMenu: Array<Menu>;
  @Input() spyScrollMenu: Array<Menu>;
  @Output() onMenuItemLinkEventEmitter: EventEmitter<any> = new EventEmitter();
  public showDropdownMenu = false;
  public readonly LOG_IN: string = 'Log In';
  public readonly LOG_OUT: string = 'Log Out';

  constructor(private router: Router) {}

  onMenuItemLinkClick(event, menu: Menu): void {
    event.preventDefault();
    this.onMenuItemLinkEventEmitter.emit(menu);
  }

  onLogoClick() {
    window.scroll(0, 0);
  }

  scrollToContent(isMobileView, menu) {
    this.router.navigate(['home'], {
      fragment: menu.url,
      preserveFragment: true,
    });
    const elem = document.getElementById(menu.url);
    elem.scrollIntoView(true);
    return false;
  }
}
