import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromFeatureToggleReducer from '../+state/feature-toggle.reducer';
import * as fromFeatureToggleActions from '../+state/feature-toggle.actions';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleActionsService {
  constructor(private store: Store<fromFeatureToggleReducer.State>) {}

  loadNoAuthFeatureToggles() {
    this.store.dispatch(fromFeatureToggleActions.loadNoAuthFeatureToggle());
  }

  loadAuthFeatureToggles() {
    this.store.dispatch(fromFeatureToggleActions.LoadAuthFeatureToggle());
  }

  cleanFeatureToggles() {
    this.store.dispatch(fromFeatureToggleActions.cleanFeatureToggle());
  }
}
