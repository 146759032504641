import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'developer-portal-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent implements OnInit {
  @Input() title: string;
  @Input() description?: string;
  @Input() backgroundUrl?: string;
  @Input() imgAltName?: string;
  @Input() headerIconUrl?: string;
  @Input() isBackNav?: boolean;
  @Input() navPath?: string;
  @Input() navText?: string;
  @Input() opacity?: string;
  @Input() fontColor?: string;

  constructor() {}

  ngOnInit(): void {}
}
