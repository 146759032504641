import { Action } from '@ngrx/store';

export enum OpenFeedbackModalActionTypes {
  OpenFeedbackModalSuccess = '[OpenFeedbackModal] Success',
}

export class OpenFeedbackModalSuccess implements Action {
  readonly type = OpenFeedbackModalActionTypes.OpenFeedbackModalSuccess;
  constructor() {}
}

export type OpenFeedbackModalActionsUnion = OpenFeedbackModalSuccess;
