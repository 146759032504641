import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { FeatureToggleService } from '../services/feature-toggle.service';
// import { loadFeatureToggleSuccess, loadFeatureToggle, loadFeatureToggleFail } from '../+state/feature-toggle.actions';
import * as FeatureToggleActions from '../+state/feature-toggle.actions';

@Injectable()
export class FeatureToggleEffects {
  constructor(
    private actions$: Actions,
    private featureToggleService: FeatureToggleService
  ) {}

  getNoAuthFeatureToggles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureToggleActions.loadNoAuthFeatureToggle),
      switchMap(() =>
        this.featureToggleService.getNoAuthFeatureToggles().pipe(
          map(
            (featureToggle) =>
              FeatureToggleActions.loadNoAuthFeatureToggleSuccess({
                featureToggle: featureToggle,
              }),
            catchError((error) =>
              of(
                FeatureToggleActions.loadNoAuthFeatureToggleFail({
                  error: error,
                })
              )
            )
          )
        )
      )
    )
  );

  getAuthFeatureToggle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureToggleActions.LoadAuthFeatureToggle),
      switchMap(() =>
        this.featureToggleService.getAuthFeatureToggles().pipe(
          map((response) =>
            FeatureToggleActions.LoadAuthFeatureToggleSuccess({
              featureToggle: response,
            })
          ),
          catchError((error) =>
            of(FeatureToggleActions.LoadAuthFeatureToggleFail({ error: error }))
          )
        )
      )
    )
  );
}
