import {
  OpenFeedbackModalActionTypes,
  OpenFeedbackModalActionsUnion,
} from '../actions/open-feedback-modal.action';

export interface State {
  loaded: boolean;
  showFeedbackModal: boolean;
}

export const initialState: State = {
  loaded: false,
  showFeedbackModal: false,
};

export function reducer(
  state: State = initialState,
  action: OpenFeedbackModalActionsUnion
): State {
  switch (action.type) {
    case OpenFeedbackModalActionTypes.OpenFeedbackModalSuccess: {
      return {
        ...state,
        loaded: true,
        showFeedbackModal: true,
      };
    }
    default:
      return state;
  }
}

export const openFeedbackModalSuccess = (state: State) =>
  state.showFeedbackModal;
