<header class="header">
  <div class="header__mobile">
    <div class="header__mobile__fixed">
      <div class="logo-container" [routerLink]="['/home']">
        <img
          src="./assets/header/developerPortal-FMLogoLockup_symbol reverse.svg"
          class="logo"
          alt="Fannie Mae Logo"
        />
      </div>
      <span class="toggle-menu">
        <ng-container *ngIf="!showDropdownMenu">
          <span
            class="toggle-menu-text"
            (click)="showDropdownMenu = !showDropdownMenu"
          >
            <img
              class="hamburger-icon"
              src="./assets/landing-page/hamburger_icon_white.svg"
            />
          </span>
        </ng-container>
        <ng-container *ngIf="showDropdownMenu">
          <span
            class="toggle-menu-close"
            (click)="showDropdownMenu = !showDropdownMenu"
          >
            <img src="./assets/images/Close_icon.svg" />
          </span>
        </ng-container>
      </span>
      <ng-container>
        <nav *ngIf="showDropdownMenu" class="nav-mobile">
          <ul class="nav__dropdown">
            <li *ngFor="let menu of siteMenu" class="nav__dropdown__links">
              <a
                *ngIf="!menu.isExternalLink && !menu?.subNav"
                href="{{ menu.url }}"
                (click)="showDropdownMenu = !showDropdownMenu"
                class="nav__dropdown__links-link"
                [routerLink]="menu.url"
                >{{ menu.title }}
              </a>

              <a
                *ngIf="!menu.isExternalLink && menu?.subNav"
                class="nav__dropdown__links-link"
                (click)="menu.showMobileSubNav = !menu.showMobileSubNav"
              >
                {{ menu.title }}
                <span
                  class="arrow"
                  [ngClass]="{ toggle: menu.showMobileSubNav }"
                ></span>
              </a>

              <div
                class="sub-nav-menu"
                *ngIf="menu?.subNav && menu?.showMobileSubNav"
              >
                <b class="arrow--top"></b>
                <ul class="sub-nav-menu__links">
                  <li
                    class="sub-nav-menu__link"
                    *ngFor="let link of menu.subNav"
                  >
                    <a
                      class="anchor__link"
                      href="{{ menu.url }}"
                      routerLink="/{{ menu.url }}/{{ link.url }}"
                      routerLinkActive="isActive"
                      (click)="showDropdownMenu = !showDropdownMenu"
                    >
                      {{ link.title }}
                    </a>
                  </li>
                </ul>
              </div>
              <a
                *ngIf="
                  menu.isExternalLink &&
                  (menu.title === LOG_IN || menu.title === LOG_OUT)
                "
                (click)="onMenuItemLinkClick($event, menu)"
                class="nav__dropdown__links-link"
                href="{{ menu.url }}"
                >{{ menu.title }}</a
              >
              <a
                *ngIf="
                  menu.isExternalLink &&
                  !(menu.title === LOG_IN || menu.title === LOG_OUT)
                "
                class="nav__dropdown__links-link"
                href="{{ menu.url }}"
                target="_blank"
                >{{ menu.title }}</a
              >
            </li>
          </ul>
        </nav>
      </ng-container>
      <span class="login"></span>
    </div>
    <!-- <div class="header__mobile__border"></div> -->
  </div>
  <div class="header-info-container">
    <ng-container *ngIf="spyScrollMenu?.length <= 0">
      <div class="header-info-desktop--hiding">
        <img
          class="desktop-logo"
          src="./assets/header/developerPortal-FMLogoLockup_symbol reverse.svg"
          [routerLink]="['/home']"
          alt="Fannie Mae image"
        />
      </div>
      <div class="header-info-mobile--hiding">
        <img
          src="./assets/landing-page/developerPortal-FMLogoLockup_symbol reverse.svg"
          (click)="onLogoClick()"
        />
      </div>
    </ng-container>
  </div>
  <nav class="nav__nested desktop-nav">
    <ng-container *ngIf="spyScrollMenu?.length <= 0">
      <ul class="navbar-nav desktop-nav__content">
        <li
          *ngFor="let menu of siteMenu"
          class="nav-item desktop-nav__links login_logout"
          [ngClass]="{
            'menu-item-logout': menu.title == LOG_OUT,
            active: loginHover == true,
            'menu-item-login': menu.title == LOG_IN
          }"
          routerLinkActive="header-nav__link desktop-nav__links__link--active"
        >
          <!-- <a *ngIf="menu.title == 'Login'" class="last_li"></a> -->
          <a
            *ngIf="!menu.isExternalLink"
            class="desktop-nav__links__link"
            dpHeaderSpyScroll
            (click)="onMenuItemLinkClick($event, menu)"
            routerLink="{{ menu.url }}"
            >{{ menu.title }}</a
          >

          <a
            *ngIf="menu.isExternalLink"
            class="desktop-nav__links__link lin-lout-child"
            routerLinkActive="header-nav__link desktop-nav__links__link--active"
            (click)="onMenuItemLinkClick($event, menu)"
            target="{{ menu.target }}"
            href="{{ menu.url }}"
          >
            <img
              class="logi-logo-icon"
              *ngIf="menu.title == LOG_IN; else logoutSVG"
              src="assets/header/LogIn-Icon.svg"
            />{{ menu.title }}
            <ng-template #logoutSVG
              ><img class="logi-logo-icon" src="assets/header/LogOut-Icon.svg"
            /></ng-template>
          </a>

          <i *ngIf="menu?.subNav" class="down"></i>
          <div class="sub-nav-menu" *ngIf="menu?.subNav">
            <b class="arrow--top"></b>
            <ul class="sub-nav-menu__links">
              <li
                class="sub-nav-menu__link"
                *ngFor="let link of menu.subNav"
                routerLinkActive="isActive"
              >
                <a
                  class="anchor__link"
                  routerLink="/{{ menu.url }}/{{ link.url }}"
                >
                  {{ link.title }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ng-content></ng-content>
    </ng-container>
  </nav>
</header>
