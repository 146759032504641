import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Util } from '../../core/util';
import {
  UserDetails,
  Session,
  RoleType,
} from '@developer-portal/shared/models';
import { UserConstants } from '@developer-portal/shared/constants';
import * as FromSharedUser from '@developer-portal/shared/data-access/user';

@Injectable()
export class RolesAuthGuardService implements CanActivate, CanLoad {
  public isInternalUserAppGenActive: boolean;

  constructor(
    private router: Router,
    private store: Store<FromSharedUser.State>
  ) {}

  canLoad(): Observable<boolean> {
    return this.canNavigateByRole();
  }

  canActivate(): Observable<boolean> {
    return this.canNavigateByRole();
  }

  canNavigateByRole(): Observable<boolean> {
    const currentUser$: Observable<any> = this.store.select(
      FromSharedUser.getCurrentUser
    );
    const isInternalKeyGenActive$: Observable<boolean> = this.store.select(
      FromSharedUser.selectIsInternalUserLoginEnabled
    );

    let isAuthorized = false;

    isInternalKeyGenActive$.subscribe((appGenActive) => {
      if (appGenActive !== undefined) {
        this.isInternalUserAppGenActive = appGenActive;
      }
    });
    currentUser$.subscribe(
      (currentUser: { user: UserDetails; session: Session }) => {
        const isFannieMaeUser: boolean =
          currentUser.user?.isFannieMaeUser ||
          Util.isBoolean(currentUser.user?.isFannieMaeUser);

        isAuthorized =
          this.hasRole(currentUser, {
            role: { type: UserConstants.APIPORT_DEVPORTAL_TSP },
          }) ||
          this.hasRole(currentUser, {
            role: { type: UserConstants.APIPORT_DEVPORTAL_ORG_ADMIN },
          }) ||
          this.hasRole(currentUser, {
            role: { type: UserConstants.FLEX_EXTERNAL_CUSTOMER },
          }) || 
          this.hasRole(currentUser, {
            role: { type: UserConstants.FLEX_INTERNAL_USER },
          }) ||
          this.hasRole(currentUser, {
            role: { type: UserConstants.FLEX_LEGAL_REVIEWER },
          }) ||
          this.hasRole(currentUser, {
            role: { type: UserConstants.FLEX_TECH_ASSET_OWNER },
          }) ||
          (isFannieMaeUser && this.isInternalUserAppGenActive);
      }
    );

    return of(isAuthorized);
  }

  hasRole(
    currentUser: { user: Partial<UserDetails>; session: Session },
    roleType: { role: RoleType }
  ): boolean {
    let isAuthorized = false;

    if (currentUser.user !== null) {
      for (const role of currentUser.user.groups) {
        if (role === roleType.role.type) {
          isAuthorized = true;
          break;
        }
      }
    }

    return isAuthorized;
  }
}
