<section class="bg-image h-screen">
  <div class="flex flex-col items-center text-center h-full justify-center">
    <p
      class="font-roboto-slab text-white font-extrabold text-10xl md:text-11xl"
    >
      404
    </p>
    <p class="font-bitter font-extrabold text-white text-medium md:text-m-30">
      Sorry...
    </p>
    <p
      class="
        try-again-text
        font-bitter font-medium
        text-white text-sm
        md:text-medium
      "
    >
      The page you’re looking for can’t be found.
    </p>
    <button
      [routerLink]="['/home']"
      class="
        flex
        items-center
        justify-center
        text-center
        cursor-pointer
        my-8
        h-10
        w-40
        bg-cornflower-blue
        rounded
        text-white
        font-sans font-normal
        text-base
      "
    >
      <img
        class="error-img w-6 h-6 m-0 items-center pr-2"
        src="./assets/error-page/home-icon.svg"
      />
      <span>Go Home</span>
    </button>
  </div>
</section>
