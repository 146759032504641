import { Action } from '@ngrx/store';

export enum ErrorActionTypes {
  SetErrors = '[Error] Set Application Errors',
  ClearErrors = '[Error] Clearing Application Errors',
}

export class SetErrors implements Action {
  readonly type = ErrorActionTypes.SetErrors;
  constructor(public payload: any) {}
}

export class ClearErrors implements Action {
  readonly type = ErrorActionTypes.ClearErrors;
  constructor() {}
}

export type ErrorActionsUnion = SetErrors | ClearErrors;
