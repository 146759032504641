import { on, createReducer, Action } from '@ngrx/store';
import * as BannerActions from '../actions/banner.actions';
import { Banner } from '../../models/banner.model';
import { HttpError } from '../../models/http-error.model';

export interface State {
  loaded: boolean;
  loading: boolean;
  bannerList: Banner[];
  error: HttpError;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  bannerList: null,
  error: null,
};

const bannerReducer = createReducer(
  initialState,
  on(BannerActions.LoadAllBannerInfo, (state) => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(BannerActions.LoadAllBannerInfoSuccess, (state, { data }) => ({
    ...state,
    loaded: true,
    loading: false,
    bannerList: data,
    error: null,
  })),
  on(BannerActions.LoadAllBannerInfoFail, (state, { err }) => ({
    ...state,
    loaded: false,
    loading: false,
    bannerList: null,
    error: err,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return bannerReducer(state, action);
}

export const isBannerListLoaded = (state: State) => state.loaded;
export const isBannerListLoading = (state: State) => state.loading;
export const getBannerListError = (state: State) => state.error;
export const getBannerList = (state: State) => state.bannerList;
