import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PostFeedback } from '../../models/post-feedback.model';
import { PrePostPaths } from '../../../../../../../libs/shared/data-access/src/constants/pre-post-paths.constants';

@Injectable()
export class HttpPostFeedbackService {
  constructor(private http: HttpClient) {}

  /**
   * Web service to post feedback
   */
  postFeedback(postbody: any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'x-fnma-recaptcha': postbody.googleV3Token,
    });

    const feedbackUrl = environment.api_end_points.postFeedback;
    return this.http
      .post<any>(feedbackUrl, postbody, { headers: headers })
      .pipe(
        map((data) => data),
        catchError((error) => _throw(error))
      );
  }
}
