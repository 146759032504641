import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PrePostPaths } from '@developer-portal/shared/constants';

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {
  constructor(private http: HttpClient) {}

  getNoAuthFeatureToggles(): Observable<any> {
    const url = PrePostPaths.pre.path + '/v1/feature-toggle';

    return this.http.get<any>(url).pipe(
      map((response: any) => response),
      catchError((error) => throwError(error))
    );
  }

  getAuthFeatureToggles(): Observable<any> {
    const url = PrePostPaths.post.path + '/v1/feature-toggle';

    return this.http.get<any>(url).pipe(
      map((response: any) => response),
      catchError((error) => throwError(error))
    );
  }

  protected mockData() {
    const mockResponse = {
      'app-creation': 'true',
      'auth-code-grant-view': 'true',
      'maintenance-banner': 'true',
    };

    return of(mockResponse);
  }
}
