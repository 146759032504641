import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Util } from '../../../core/util';

import * as fromFeatureToggle from '../../../store/reducers/index';

@Injectable({ providedIn: 'root' })
export class ApplicationErrorGuard implements CanActivate {
  public Util: Util;

  constructor(private store: Store<fromFeatureToggle.State>) {
    this.Util = new Util();
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let canActivate = false;

    this.store
      .pipe(select(fromFeatureToggle.getGlobalErrorState))
      .subscribe((hasError: any) => {
        if (window.location.hostname !== 'localhost') {
          if (
            hasError != null &&
            hasError != undefined &&
            hasError['status'] > 200
          ) {
            canActivate = true;
          }
        }
      });

    return of(canActivate);
  }
}
