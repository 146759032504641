import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 * application footer
 * This component serves as the application footer to include fannieMae logo, home site link, catalog page link, etc.
 */
@Component({
  selector: 'dp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public copyrightContent: string;
  @Input() siteFooter: string;

  constructor() {}
  ngOnInit() {
    this.copyrightContent = `© ${new DatePipe('en-US').transform(
      new Date(),
      'yyyy'
    )} Fannie Mae. All rights reserved.`;
  }
}
