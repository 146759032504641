<section class="error-page">
  <div class="error-page-header">
    <img class="error-img" src="./assets/errors/500_error_img.svg" />
    <div class="error-message">
      <p class="error-text">{{ serviceErrorText }}</p>
      <p class="try-again-text">{{ checkLater }}</p>
    </div>
    <a [routerLink]="['/home']" class="home-navigation">Try Again</a>
  </div>
</section>
